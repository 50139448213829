import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectHeaderDescriptionText } from 'lib/util/privateMarketplace';
import { isEssentialPagePath } from 'lib/util/isEssentialPagePath';
import { PMPFooterView } from 'components/PrivateMarketplace/PMPFooterView/PMPFooterView';
import { Header } from 'components/PrivateMarketplace/Header/Header';
import { useRouter } from 'next/router';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';

type Props = {
  children: React.ReactNode;
};

const vibrantThemePathShouldShowHeader = (path: string): boolean => {
  if (isEssentialPagePath(path)) {
    return true;
  }

  if (path.includes('checkout')) {
    return true;
  }

  const matchResult = path.match(/top\/([^/]+)/);
  if (!matchResult) {
    return false;
  }

  if (matchResult[1] === 'products') {
    return false;
  }

  // bookmarked, popular, recommended, visited, category, and feature pages should all show
  // the header
  return true;
};

export const PrivateMarketplacePage = ({ children }: Props) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const router = useRouter();

  const shouldShowHeader =
    bookingWidgetTheme !== 'VIBRANT' || vibrantThemePathShouldShowHeader(router.asPath ?? '');

  const headerDescriptionText = useSelector(selectHeaderDescriptionText);

  return (
    <div className="base-wrap">
      {shouldShowHeader && (
        <>
          <div className="base-h1">{headerDescriptionText}</div>
          <Header />
        </>
      )}
      <main className="pmp-base-main">{children}</main>
      <PMPFooterView />
    </div>
  );
};
