// @flow

import * as React from 'react';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { hidePopup, selectHiddenPopups } from 'ducks/client/hiddenPopupMessages';
import { usePopupMessage } from 'hooks/usePopupMessage';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useQueryString } from 'hooks/useQueryString';

import styles from './PopupMessage.module.css';
import { ReduxState } from 'ducks';

export const PopupMessage = () => {
  const router = useRouter();
  const [show, setShow] = React.useState(false);

  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const { apiKey, useApiKeyInPaths } = React.useContext(ApiKeyContext);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 2 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const hiddenPopupMessageKeys = useSelector(selectHiddenPopups);
  const popupMessage = usePopupMessage();

  const isTopPage = router.pathname === '/top' || router.pathname === '/[apiKey]';

  const defaultPopupPaths: string[] = ['/top', '/[apiKey]'];

  for (const pathPrefix of ['/top', '/[apiKey]', '']) {
    defaultPopupPaths.push(
      ...['about', 'faq', 'covid19', 'news', 'news/[id]', 'article/[id]', 'products/[id]'].map(
        (p) => `${pathPrefix}/${p}`
      )
    );
  }

  defaultPopupPaths.push(
    '/top/[categoryFeatureName]',
    '/top/bookmarked',
    '/top/popular',
    '/top/recommended',
    '/top/visited'
  );

  const queryString = useQueryString();

  if (
    popupMessage == null ||
    !show ||
    (popupMessage.display_type === 'TOP_PAGE_ONLY' && !isTopPage) ||
    (popupMessage.display_type === 'ALL_PAGES' && !defaultPopupPaths.includes(router.pathname)) ||
    hiddenPopupMessageKeys.includes(popupMessage.key)
  ) {
    return null;
  }

  const timezone = settings.default_timezone ?? 'Asia/Tokyo';
  const todayInTimezone = moment.tz(timezone).format('YYYY-MM-DD');
  if (
    todayInTimezone < popupMessage.display_start_date ||
    popupMessage.display_end_date < todayInTimezone
  ) {
    return null;
  }

  return (
    <div className={styles['snackbar-container']}>
      <div className={clsx(styles['snackbar'])}>
        <a
          onClick={() => {
            dispatch(hidePopup(popupMessage.key));
            setShow(false);
          }}
          className={clsx(styles['snackbar-close'])}
        />
        {popupMessage.image_url && <img src={popupMessage.image_url} />}
        <Box
          display="flex"
          justifyContent="center"
          ml={1}
          mt={2}
          mb={2}
          className={styles['snackbar-title']}
        >
          {popupMessage.title}
        </Box>
        {popupMessage.description && (
          <Box display="flex" justifyContent="center" ml={1} mb={2}>
            {popupMessage.description}
          </Box>
        )}
        {popupMessage.button_text && (
          <Box mb={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(hidePopup(popupMessage.key));
                setShow(false);

                switch (popupMessage.button_destination_type) {
                  case 'FULL_URL':
                    router.push(popupMessage.button_destination_url);
                    return;
                  default: {
                    const destinationPath = appendQueryString(
                      useApiKeyInPaths
                        ? `/${apiKey}${popupMessage.button_destination_page_path}`
                        : popupMessage.button_destination_page_path,
                      queryString
                    );
                    router.push(destinationPath);
                  }
                }
              }}
            >
              {popupMessage.button_text}
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};
