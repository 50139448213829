import * as React from 'react';
import { useSelector } from 'react-redux';

import config from 'config';
import {
  CustomerTokenType,
  CustomerTokenContextType,
  CustomerTokenContext,
} from 'contexts/CustomerTokenContext';
import { ReduxState } from 'ducks';

export const useCustomerToken = (): CustomerTokenContextType => {
  const context = React.useContext(CustomerTokenContext);

  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  const now = new Date().getTime();

  if (context?.expiresAt !== 0 && (context?.expiresAt ?? 0) < now - 5 * 60 * 1000) {
    if (context?.idProvider === 'FACEBOOK') {
      context.logout();
    } else {
      if (
        (context.idProvider === 'COGNITO' || context.idToken === 'GOOGLE') &&
        context?.refreshToken
      ) {
        let tokenUrl = '';
        let clientId = '';
        if (context?.idProvider === 'GOOGLE') {
          tokenUrl = 'https://www.googleapis.com/oauth2/v4/token';
          clientId = config.googleAuth.clientId;
        } else {
          tokenUrl = `https://${
            apiSettings?.customer_ledger_settings?.cognito?.domain ?? ''
          }/oauth2/token`;
          clientId = apiSettings.customer_ledger_settings?.cognito?.client_id ?? '';
        }
        fetch(tokenUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `grant_type=refresh_token&client_id=${clientId}&refresh_token=${context.refreshToken}`,
        })
          .then((response) => response.json())
          .then((data) => {
            const newContext: CustomerTokenType = {
              accessToken: data.access_token,
              idToken: data.id_token,
              refreshToken: data.refresh_token,
              expiresAt: now + data.expires_in * 1000,
              idProvider: context.idProvider,
            };
            context.setToken(newContext);
          })
          .catch((error) => {
            console.error(error);
            context.logout();
          });
      }
    }
  }

  return context;
};
